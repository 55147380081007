function Contacts() {
    return ( 
        <div className="text-center">
            <p className="avenir-heavy text-white text-xl my-3">معلومات التواصل</p>
            <ul className="flex-box-center gap-3 flex-col avenir-book text-white">
                <li>+966 245782896</li>
                <li>info@track.com</li>
                <li>+966 415782456</li>
            </ul>
        </div>
     );
}

export default Contacts;